import React from 'react'
import { NavLink } from 'react-router-dom'
import NavBar from '../Navbar/NavBar'
import { useEffect } from 'react'
import lottie from 'lottie-web'
import animationData from '../../Assets/images/home.json'

const HeroSection = () => {

    useEffect(() => {
        let animationContainer = document.getElementById("animation-container");


        if (animationContainer.innerHTML.length === 0) {
            lottie.loadAnimation({
                container: animationContainer,
                animationData: animationData,
                renderer: 'svg',
                loop: true,
                autoplay: true,
            });
        }
    }, [])
    return (
        <>
            <div className="hero-section" style={{ width: '100%' }}>
                <NavBar />
                <div className="container ">
                    <div className="row">
                        <div className="col-md-7 pt-5 hero-box ">
                            <h1 className='fw-bold '>VIRTUAL </h1>
                            <h1 className='hero-section-heading '>  <span>PHARMACY</span></h1>

                            <h1 className='fw-bold '> SOLUTIONS</h1>

                            <p className='mt-3 fw-bold'>Your trusted destination for superior Healthcare</p>
                            <div className="d-flex align-items-center">
                                <NavLink>
                                    <img src={require('../../Assets/images/AppStore.png')} alt="appstore" width='131px' className='me-3 app' />
                                </NavLink>
                                <NavLink>
                                    <img src={require('../../Assets/images/GooglePlay.png')} alt="playstore" width='140px' className='play' />
                                </NavLink>

                            </div>
                        </div>
                        <div className="col-md-5 text-end " id='animation-container'>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HeroSection