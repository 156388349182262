import React from 'react'
import NavBar from './../../Components/Navbar/NavBar';
import style from './contactHeroSection.module.css'
const ContactHeroSection = () => {
    return (
        <>
            <div className={style.contactHeroSection}>
                <NavBar />

                <div className="container ">
                    <div className="row">
                        <div className="col-md-7 pt-5 padding hero">

                            <h1 className='hero-section-heading'>  <span >GET IN TOUCH WITH US</span></h1>

                            <div className={`d-flex  align-items-center`}>
                                <p className='mt-4 fw-bold '>Our dedicated support team is available to address your needs and ensure that your experience with us remains exceptional. Whether you need an assistance with placing and order, have querries about our products or services, or require any other assistance,  we are just a messege away. </p>
                            </div>
                        </div>
                        <div className="col-md-5 text-end ">
                            <img src={require('../../Assets/images/animation_lkjgppde.png')} alt="animation-img" className='img-fluid ' width='100%' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactHeroSection