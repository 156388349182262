import React from 'react'
import style from './BlogsHeroSection.module.css'
import NavBar from '../../Components/Navbar/NavBar'
import { useEffect } from 'react'
import lottie from 'lottie-web'
import animationData from '../../Assets/images/about.json'
const BlogsHeroSection = () => {

    useEffect(() => {
        let animationContainer = document.getElementById("animation-container");

        console.log(animationContainer.innerHTML.length,'asasasas')


        if (animationContainer.innerHTML.length===0) {
            lottie.loadAnimation({
                container: animationContainer,
                animationData: animationData,
                renderer: 'svg',
                loop: true,
                autoplay: true,
            });
        }
    }, [])

    return (
        <div className={style.BlogsHeroSection}>
            <NavBar />
            <div className="container ">
                <div className="row">
                    <div className="col-md-7 pt-5 hero ">

                        <h1 className='hero-section-heading'>  <span >HEALTHWISE</span></h1>

                        <p className='mt-5 fw-bold'>From practical tips on healthy living in-depth explorations of medical advantages, we are here to empower with the knowledge  you need to make positive and informed choices.</p>


                    </div>
                    <div className="col-md-5 text-end " id='animation-container'>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogsHeroSection