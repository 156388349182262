import React from 'react'
import Slider from "react-slick";
const ImgSlider = () => {

    const settings = {
        dots: false,
        infinite: true,

        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [{
            breakpoint: 1024,
            settings: {
                dots: false,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1

            }
        },
        {
            breakpoint: 600,
            settings: {
                dots: false,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        }
       
        ]
    };
    return (
        <>
            <div className="container-fluid image-slider p-5">
                <h1 className='text-center pb-5 '>TESTIMONIALS</h1>
                <p className='pb-5'></p>
                <div className="row">

                    <div className="col-lg-8 mx-auto  " >

                        <Slider {...settings}>


                            <div className='slider' >
                                <div className="d-flex mt-4 w-100 justify-content-start">
                                    <i className="fa-solid fa-quote-left"></i>
                                </div>
                                <div className='img-box'>

                                    <img src={require('../../../Assets/images/avatara.jpg')} width='150px' />
                                </div>
                                <p>"Being a busy professional, I always struggle to find time to visit a pharmacy for my regular medications. Thanks to InpharmaC, I can now conveniently order my prescriptions online and have them delivered right to my doorstep. It's hassle-free & a live safer for someone like me."</p>
                                <h3>Ayesha Khan</h3>
                            </div>
                            <div className='slider'>
                                <div className="d-flex mt-4 w-100 justify-content-start">

                                    <i className="fa-solid fa-quote-left"></i>
                                </div>
                                <div className="img-box">

                                    <img src={require('../../../Assets/images/avatara.jpg')} width='150px' />
                                </div>

                                <p>"Being a busy professional, I always struggle to find time to visit a pharmacy for my regular medications. Thanks to InpharmaC, I can now conveniently order my prescriptions online and have them delivered right to my doorstep. It's hassle-free & a live safer for someone like me."</p>
                                <h3>Ayesha Khan</h3>
                            </div>
                            <div className='slider'>
                                <div className="d-flex mt-4 w-100 justify-content-start">

                                    <i className="fa-solid fa-quote-left"></i>
                                </div>
                                <div className="img-box">

                                    <img src={require('../../../Assets/images/avatara.jpg')} width='150px' />
                                </div>
                                <p>"Being a busy professional, I always struggle to find time to visit a pharmacy for my regular medications. Thanks to InpharmaC, I can now conveniently order my prescriptions online and have them delivered right to my doorstep. It's hassle-free & a live safer for someone like me."</p>
                                <h3>Ayesha Khan</h3>
                            </div>


                        </Slider>


                    </div>
                </div>













            </div>


        </>
    )
}

export default ImgSlider