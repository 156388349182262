import React from 'react'

const SecurePayment = () => {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="d-flex align-items-center my-col justify-content-between">

                        <div className="col-md-5 ">

                            <img src={require('../../Assets/images/animation_lkjkv973.png')} alt="img" className='img-fluid w-100' />
                        </div>


                        <div className="col-md-5 order order">
                            <h1 style={{ color: '#16B5FF' }} >
                                SECURE PAYMENTS OPTIONS
                            </h1>
                            <div className="shr">

                            </div>
                            <p>Our trusted platform ensure secure and encrypetd payment getways to protect customers's financial information. </p>

                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default SecurePayment