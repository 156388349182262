import React from 'react'
import OpenATicket from './OpenATicket'

const Bg = () => {
    return (
        <>
            <div className="bg" >
                <div className="container-fluid">
                    <div className="row">
                        <OpenATicket />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Bg