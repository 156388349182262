import React from 'react'
import Numbers from './Numbers'
import ImgSlider from '../../Pages/About/ImgSlider/ImgSlider'
import Numbers2 from './Numbers2'
import Numbers3 from './Numbers3'


const Rating = () => {
    return (
        <>
            <div className="rating container mt-3 ">
                <div className="row p-5 ">
                    <div className="border" style={{ backgroundColor: "#00afe7 ", borderRadius: "13px " }}>
                        <div className="row p-3  align-items-center">
                            <div className="d-flex p-5 align-items-center  padding-remove">

                                <div className="col-md-4 ">
                                    <Numbers />
                                </div>
                                <div className="col-md-4">
                                    <Numbers2 />
                                </div>
                                <div className="col-md-4">
                                    <Numbers3 />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mx-0 p-5  mt-5 feel-the-best text-white text-center">
                        <h1 style={{ color: 'black' }}>Take Small Setps To <span style={{ color: "#f8bd43" }}>Feel Your Best</span></h1>
                        <p style={{ color: 'black' }}>We are here to help you stay on track with vitamins and suppliments</p>
                    </div>
                </div>
            </div>
            <ImgSlider />
        </>
    )
}

export default Rating