import React from 'react'

const AboutServices3 = () => {
    return (
        <>
            <div className="container servies" >
                <div className="row py-5 r-p ">
                    <div className="col-md-5 ms-auto order">
                        <h1 style={{ color: '#16B5FF' }} >MEDICINE <br /> DELIVERY</h1>
                        <div className="ahr">
                            
                        </div>
                        <p>Customer will receive their particular medicine easily.</p>

                    </div>
                    <div className="col-md-6">
                        <img src={require('../../../Assets/images/Group 132.png')} alt="24 hours services" className='img-fluid d-block' width='100% ' />

                    </div>

                </div>

                <div className="row">
                    <div className="d-flex align-items-center justify-content-center">
                        <div className="col-md-8 text-capitalize p-4" style={{ backgroundColor: '#00ADE5', color: 'white', borderRadius: '10px', margin: '20px 0px' }}>
                            <h3 className='text-center'>Prescriptions in the palm of your hand <br /> Its never been easy before.</h3>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default AboutServices3