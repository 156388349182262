import React from 'react'

const HaveAnyQuestion = () => {
    return (
        <>
            <div className="my-5">

                <div className="container">
                    <div className="row justify-content-center padding">
                        <div className="col-md-8" style={{ padding: '5px 10px', backgroundColor: '#00ADE5', borderRadius: "8px", }}>
                            <h1 className='text-center text-white size' >Have A Question? Drop A Line!</h1>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HaveAnyQuestion