import React from 'react'

const AboutServices2 = () => {
    return (
        <>

            <div className="container servies" >
                <div className="row py-5 r-p ">

                    <div className="col-md-6  ">
                        <img src={require('../../../Assets/images/Group 131.png')} className='img-fluid w-100 d-block ' />

                    </div>
                    <div className="col-md-5 ms-auto order">
                        <h1 style={{ color: '#16B5FF' }} >CUSTOMER <br /> ORDER</h1>
                        <div className="ahr">
                          
                        </div>
                        <p >Customers will order medicine through our app.</p>

                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutServices2