import React from 'react'
import { NavLink } from 'react-router-dom'
const SocialLinks = () => {
    return (
        <>
            <div className="social-links mt-3">
                <div className="container">
                    <div className="row">
                        <div className="d-flex align-items-center justify-content-center">
                            <NavLink to='' >
                                <div className="circle">
                                    <i className="fa-brands fa-facebook"></i>
                                </div>
                            </NavLink>
                            <NavLink to='' >
                                <div className="circle">
                                    <i className="fa-brands fa-twitter"></i>
                                </div>
                            </NavLink>
                            <NavLink to='' >
                                <div className="circle">
                                    <i className="fa-brands fa-instagram"></i>
                                </div>
                            </NavLink>
                            <NavLink to='' >
                                <div className="circle">
                                    <i className="fa-brands fa-google"></i>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                    <div className="row">
                        <div className="d-flex align-items-cente justify-content-center">
                            <p className='fw-bold'>InPharmC 2023. All rights reserved. </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SocialLinks