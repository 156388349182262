import React from 'react'
import Srvc from './Srvc'
import Srvc2 from './Srvc2'
import Srvc3 from './Srvc3'
import SecurePayment from './SecurePayment'
import CustomerSupport from './CustomerSupport';
const MyAllServices = () => {
    return (
        <>
            <Srvc />
            <Srvc2 />
            <Srvc3 />
            <SecurePayment />
            <CustomerSupport />
        </>
    )
}

export default MyAllServices