import React from 'react'

const MoreAboutUs = () => {
    return (
        <>
            <div className="container  " style={{ marginTop: '130px' }}>
                <div className="row">
                    <div className="d-flex align-items-center flex-column jusitfy-content-between">
                        <h2 style={{ fontSize: '45px',fontWeight:'bold'}} className='mb-4 headingTwo'>Want to know more about us?</h2>
                        <button type='button' className='btn btn-lg btn-info rounded-1 text-white' style={{backgroundColor:'#16B5FF'}}>Learn More</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MoreAboutUs