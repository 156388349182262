import React from 'react'
import Slider from "react-slick";
import { NavLink } from 'react-router-dom'

const ResgisterPharmacies = () => {
    const settings = {
        dots: false,
        infinite: true,
     
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [{
            breakpoint: 1024,
            settings: {
                dots: false,
                infinite: true,
                speed: 500,
                slidesToShow: 3,
                slidesToScroll: 1

            }
        },
        {
            breakpoint: 600,
            settings: {
                dots: false,
                infinite: true,
                speed: 500,
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 450,
            settings: {
                dots: false,
                infinite: true,
                speed: 500,
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        }]
    }
    return (
        <>
            <div className="container p-3 mt-5 register-pharmacies">
                <div className="row gutter">
                    <h2 className='text-center mb-5 headingTwo' style={{ fontSize: '35px', fontWeight: 'bold' }}>Some of our Registered Pharamacies</h2>
                    <div className="row py-3 gutter align-items-center justify-content-center" style={{ backgroundColor: " #f6f6f6", borderRadius: '8px' }} >
                        <Slider {...settings} >

                            <div className="col-sm-2 d-flex align-items-center justify-content-center">
                                <NavLink to=''>
                                    <div style={{ height: '150px', width: '150px' }}>
                                        <img src={require('../../Assets/images/dwatson.png')} alt="pharmacy" style={{ width: '100%', height: '100%' }} />
                                    </div>
                                </NavLink>
                            </div>
                            <div className="col-sm-2 d-flex align-items-center justify-content-center">
                                <NavLink to=''>
                                    <div style={{ height: '150px', width: '150px' }}>
                                        <img src={require('../../Assets/images/azeempharmacy.png')}  alt='pharmacy' style={{ width: '100% ', height: '100%' }} />
                                    </div>
                                </NavLink>
                            </div>
                            <div className="col-sm-2 d-flex align-items-center justify-content-center">
                                <NavLink to=''>
                                    <div style={{ height: '150px', width: '150px' }}>
                                        <img src={require('../../Assets/images/hairfree.png')} alt="pharmacy" style={{ width: '100%', height: '100%' }} />
                                    </div>
                                </NavLink>
                            </div>
                            <div className="col-sm-2 d-flex align-items-center justify-content-center">
                                <NavLink to=''>
                                    <div style={{ height: '150px', width: '150px' }}>
                                        <img src={require('../../Assets/images/servaid.png')} className='img-fluid ' alt="pharmacy" style={{ width: '100%', height: '100%' }} />
                                    </div>
                                </NavLink>
                            </div>
                            <div className="col-sm-2 d-flex align-items-center justify-content-center">
                                <NavLink to=''>
                                    <div style={{ height: '150px', width: '150px' }}>
                                        <img src={require('../../Assets/images/khanmedical.png')} className='img-fluid ' alt="pharmacy" style={{ width: '100%', height: '100%' }} />
                                    </div>
                                </NavLink>
                            </div>
                        </Slider>

                    </div>
                </div>
            </div >
        </>
    )
}

export default ResgisterPharmacies



