import React from 'react'
import { NavLink } from 'react-router-dom'
const Footer = () => {
    return (
        <div className="footer" style={{ backgroundColor: "#00ade5" }}>
            <div className="container padding  pt-5" >

                <div className="row ">
                    <div className="row g-0 px-3 last-change py-5 bg-white rounded">
                        <div className="col-md-4 ">
                            <div className="d-flex align-items-center justify-content-center address-box start">
                                <i className='fa-solid fa-location-dot'></i>
                                <div>
                                    <h3 className='m-0 p-0'>Our Address</h3>
                                    <small className='m-0 p-0'>Quetta Balochistan</small>
                                </div>
                            </div>


                        </div>
                        <div className="col-md-4 ">
                            <div className="d-flex start align-items-center address-box justify-content-center">
                                <i className='fa-solid fa-phone '></i>
                                <div className=''>
                                    <h3 className='m-0 p-0'>Call Us</h3>
                                    <small className='m-0 p-0'>+92**********</small>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 ">
                            <div className="d-flex start align-items-center address-box justify-content-center">
                                <i className='fa-solid fa-message'></i>
                                <div>
                                    <h3 className='m-0 p-0'>Our Address</h3>
                                    <small className='m-0 p-0'>Quetta Balochistan</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row p-0 mt-3">
                        <div className="col-md-5">
                            <div className="d-flex align-items-start flex-column info">
                                <div className="logo-box">
                                    <img src={require('../../Assets/images/istockphoto-1275720974-612x612.png')} alt="logo" />
                                </div>
                                <h1 className='text-white'>InPharmaC</h1>
                                <p className='text-white mt-2'>Our online pharmacy is dedicated to delivering the highest standered care and convinence to enhance your well-being.</p>
                            </div>
                        </div>
                        <div className="col-md-3 ms-auto links">
                            <h2 className='text-white'>Useful Links</h2 >
                            <ul className='p-0'>
                                <li>
                                    <NavLink to='/'>Conditions</NavLink>
                                </li>
                                <li>
                                    <NavLink to='/'>Terms of Use</NavLink>
                                </li>
                                <li>
                                    <NavLink to='/my_services'>Our Services</NavLink>
                                </li>
                                <li>
                                    <NavLink to='/contact_us'>Contact Us</NavLink>
                                </li>
                                <li>
                                    <NavLink to='/about'>About Us</NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3 text-white news-letter">
                            <h2>NewsLetter</h2>
                            <p>Join Our Newsletter <br /> help</p>
                            <input type="email" id="email" placeholder='Email' />
                            <div className='mt-3'>
                                <NavLink className='me-3'>Privacy</NavLink>
                                <NavLink >Terms</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Footer