import React from 'react'
import HeroSection from '../HeroSection/HeroSection'
import WhatWeProvide from '../WhatWeProvide/WhatWeProvide'
import Services from '../WhatWeProvide/Services'
import Services2 from '../WhatWeProvide/Services2'
import Services3 from '../WhatWeProvide/Services3'
import MoreAboutUs from '../MoreAboutUs/MoreAboutUs'
import ResgisterPharmacies from '../RegisterPharmacies/ResgisterPharmacies'
import NewTechnologies from '../NewTechnologies/NewTechnologies'
import Rating from '../Rating/Rating'
import Footer from '../Footer/Footer'
import SocialLinks from '../SocialLinks/SocialLinks'
const Home = () => {
    return (
        <>
            <HeroSection />
            <WhatWeProvide />
            <Services />
            <Services3 />
            <Services2 />
            <MoreAboutUs />
            <ResgisterPharmacies />
            <NewTechnologies />
            <Rating />
            <Footer />
            <SocialLinks />
        </>
    )
}

export default Home