import React from 'react'
import ServicesHeroSection from './ServicesHeroSection'
import TakeALook from './TakeALook'
import NewTechnologies from '../../Components/NewTechnologies/NewTechnologies'
import Footer from '../../Components/Footer/Footer'
import SocialLinks from '../../Components/SocialLinks/SocialLinks'
import Rating from '../../Components/Rating/Rating'
import MyAllServices from './MyAllServices'

const MyServices = () => {
    return (
        <>
            <ServicesHeroSection />
            <TakeALook />
            <MyAllServices />
            <NewTechnologies />
            <Rating />
            <Footer />
            <SocialLinks />
        </>
    )
}

export default MyServices