import React from 'react'


const Srvc = () => {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="d-flex align-items-center my-col justify-content-between">
                        <div className="col-md-5">
                            <img src={require('../../Assets/images/8607891-removebg-preview.png')} alt="img" className='img-fluid w-100' />
                        </div>
                        <div className="col-md-6">
                            <div className="priscription-picture d-flex align-items-center w-100 ">
                                <img src={require('../../Assets/images/animation_lk9i61mh.png')} alt="ad" />
                                <h2>By
                                    Prescription Picture</h2>
                            </div>
                            <p>
                                "Here you can uplaod prescription images and send them to sellers, who will then provide a price within 5 minutes. Each seller will send their own offer from the option availabe."
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Srvc