import React from 'react'
import style from './SupportHeroSection.module.css'
import NavBar from '../../Components/Navbar/NavBar'
const SupportHeroSection = () => {
    return (
        <div className={style.SupportHeroSection}>
        <NavBar />

        <div className="container ">
            <div className="row">
                <div className="col-md-7 pt-5 hero-box padding hero
                 ">

                    <h1 className='hero-section-heading'>  <span >Support</span></h1>

                    <div className={`d-flex  align-items-center`}>
                      <p className='mt-4 fw-bold '>Our dedicated support team is availabel to address your needs and ensure that your experience with us remains exeptional. Whether you need an assistance with placing and order, have querries about our products or services, or require any other assistance,  we are just a messege away. </p>
                    </div>
                </div>
                <div className="col-md-5 text-end p-3 ">
                    <img src={require('../../Assets/images/lady.png')} alt="animation-img" className='img-fluid ' width='100%' style={{borderRadius:'15px'}} />
                </div>
            </div>
        </div>
    </div>
  )
}

export default SupportHeroSection