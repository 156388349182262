import React from 'react'

const AboutServices = () => {
    return (
        <>

            <div className="container servies" >
                <div className="row py-5 r-p ">

                    <div className="col-md-5  ">
                        <img src={require('../../../Assets/images/kerfin-1.png')} alt="24 hours services" width='100%'  className='hide-img'/>

                    </div>
                    <div className="col-md-6 ms-auto order">
                        <h1 style={{ color: '#16B5FF' }} >PHARMACY <br /> REGISTRATION</h1>
                        <div className="ahr">
                           
                        </div>
                        <p>They will register their pharmacy on our trusted platforms.</p>
                        <img src={require('../../../Assets/images/registration.png')} alt="img" className='img-fluid  ' style={{display:'block'}} />
                    </div>
                </div>
            </div></>
    )
}

export default AboutServices