import React from 'react'

const BlogRating = () => {
    return (
        <>
            <div className="container mb-5">

                <div className="row mx-0 p-5  mt-5 feel-the-best text-white text-center">
                    <h1>Take Small Setps To <span style={{ color: "#f8bd43" }}>Feel Your Best</span></h1>
                    <p>We are here to help you stay on track with vitamins and suppliments</p>
                </div>
            </div>
        </>
    )
}

export default BlogRating