import './App.css';
import Home from './Components/Home/Home';
import { Route, Routes } from 'react-router-dom';
import About from './Pages/About/About';
import ContactUs from './Pages/ContactUs/ContactUs';
import Support from './Pages/Support/Support';
import Blogs from './Pages/Blogs/Blogs';
import MyServices from './Pages/Services/MyServices';
import './App.css'

function App() {

  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/about' element={<About />} />
      <Route path='/my_services' element={<MyServices />} />
      <Route path='/blogs' element={<Blogs />} />
      <Route path='/contact_us' element={<ContactUs />} />
      <Route path='/support' element={<Support />} />
    </Routes>

  );
}

export default App;
