import React from 'react'

const NewTechnologies = () => {
    return (
        <>
            <div className="container-fluid new-technologies p-5 mt-5">

                <video src={require('../../Assets/images/VID-20230810-WA0004.mp4')} type="video/mp4" autoPlay muted loop className='w-100' >
                   
                    Your browser does not support the video tag.
                </video>

                <h1 className='text-center text-white'>Why Choose Us?</h1>
                <p className='text-center text-white fw-normal px-3 mt-2'>At our online pharmacy we take immense pride in delivering the finist online pharmacy experience to our valued customers. Here is how we ensure excellence in every aspect of our service.</p>
                <div className="row remove-padding p-5 ">
                    <div className="col-md-6">
                        <div className="d-flex flex-column align-items-start">
                            <div className="d-flex align-items-center ">
                                <div className="border">
                                    <i className="fa-solid fa-gem"></i>
                                </div>
                                <div className='d-flex flex-column align-items-start'>
                                    <h4 className='ms-3'>Newest Technologies</h4>
                                    <small className='ms-3 text-white'>Streaming your notification needs with cutting-edge solutions.</small>
                                </div>

                            </div>
                            <p className='mt-4 text-white'>By Chosing our online pharmacy, you are entrusting your health and well-being to a team committed to providing the best possible service. We continually strive to enhance our offerings, improve customer experiences, and maintain the heighest standards of pharamaceutical care. Thank you for choosing us as your prefered online pharmacy. We look forward serving you and supporting your journey towards healthier life.</p>
                        </div>
                        <div className="d-flex flex-column mt-5 align-items-start">
                            <div className="d-flex align-items-center ">
                                <div className="border">
                                    <i class="fa-solid fa-earth-oceania"></i>

                                </div>
                                <div className='d-flex flex-column align-items-start'>
                                    <h4 className='ms-3 '>Taking Care of Nature</h4>
                                    <small className='ms-3 text-white'>Join us in preserving nature as we deliver care straight to your door.</small>
                                </div>

                            </div>
                            <p className='mt-4 text-white'>At our online pharmacy, you are dedicated for delivering a turely exceptional and unparalalled pharmaceutical experience. We believe that access to quality healthcare should be convenient, secure and personalized, and we strive to embody these principals in every aspect of our service.</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <img src={require('../../Assets/images/kerfin-1.png')} alt="krefin" className='img-fluid' />
                    </div>
                </div>
            </div>
        </>
    )
}

export default NewTechnologies
