import React from 'react'
import CountUp from 'react-countup';

const Numbers = () => {
    return (
        <>
            <div className="d-flex align-items-center  justify-content-center flex-column">
                <h2>
                    <CountUp start={0} end={100} duration={2.5} enableScrollSpy={true} />+
                </h2>
                <p>Customers</p>
            </div>
        </>
    )
}

export default Numbers