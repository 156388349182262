import React from 'react'

const Services3 = () => {
    return (
        <>
            <div className="container servies" style={{ marginBottom: "130px" }}>
                <div className="row col py-3 order ">
                    <div className="col-md-5 img-setting position-relative minus">
                        <img src={require('../../Assets/images/doctor.png')} alt="24 hours services" className='img-5' />
                        <img src={require('../../Assets/images/medicine.png')} alt=" hospital " className='img-6' />
                    </div>
                    <div className="col-md-6 ms-auto order">
                        <h1 style={{ color: '#16B5FF' }} >QUALITY & <br />  AUTHENTICITY</h1>
                       <div className="shr">
                       
                       </div>
                        <p>Rest assured that all our medications are sourced from reputable pharamaceutical manufacturers and distributers.We strictly adhere to industry standards to provide you with safe, effective and genuine products.</p>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Services3