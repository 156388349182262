import React from 'react'

const Srvc3 = () => {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="d-flex align-items-center my-col justify-content-between">
                        <div className="col-md-5">
                            <img src={require('../../Assets/images/8607891-removebg-preview.png')} alt="img" className='img-fluid w-100' />
                        </div>
                        <div className="col-md-6">
                            <div className="priscription-picture d-flex align-items-center w-100 ">
                                <img src={require('../../Assets/images/png-transparent-white-bandage-wound-injury-wounds-hand-people-medical-removebg-preview.png')} alt="ad" />
                                <h2>By Injury Picture</h2>
                            </div>
                            <p>
                               "Here you can uplaod your injury image, you gender and you budget and then the selleres will send you the requests."
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Srvc3