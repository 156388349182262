import React from 'react'
import SupportHeroSection from './SupportHeroSection'
import Footer from '../../Components/Footer/Footer'
import SocialLinks from '../../Components/SocialLinks/SocialLinks'
import Bg from './Bg'
const Support = () => {
    return (
        <>
            <SupportHeroSection />
            <Bg />
            <Footer />
            <SocialLinks />
        </>
    )
}

export default Support