import React from 'react'
const Services2 = () => {
    return (
        <>
            <div className="container servies" >
                <div className="row col py-3">
                    <div className="col-md-6 order">
                        <h1 style={{ color: '#16B5FF' }} >LICENSED <br /> PHARMACISTS</h1>
                        <div id='shr' className="shr">

                        </div>
                        <p>Our team of licensed  pharmacists is always available to address your questions and concerns.They can provide expert advice on medication usage, potential interations, & offers personalize recommendations based on your specific health conditions.</p>
                    </div>
                    <div className="col-md-5 img-setting position-relative ms-auto">
                        <img src={require('../../Assets/images/4029328_16209.png')} alt="24 hours services" className='img-3' />
                        <img src={require('../../Assets/images/medicine.png')} alt=" hospital " className='img-4' />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Services2