import React from 'react'

const Services = ({ name, description, img1, img2 }) => {
    return (
        <>
            <div className="container servies" style={{ marginBottom: "130px", marginTop: '30px' }}>
                <div className="row  col py-3">
                    <div className="col-md-6 order">
                        <h1 style={{ color: '#16B5FF' }} >CONVENIENCE</h1>
                        <div className="shr">

                        </div>
                        <p>We understand that your time is valuabel.
                            With our online platform, you can easily browse,  select  & purchase your medicatins from the comfort of your home.</p>
                    </div>
                    <div className="col-md-5 img-setting position-relative ms-auto">
                        <img src={require('../../Assets/images/24hours.png')} alt="24 hours services" className='img-1' />
                        <img src={require('../../Assets/images/hospital.png')} alt=" hospital " className='img-2' />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Services