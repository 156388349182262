import React from 'react'
import NavBar from '../../../Components/Navbar/NavBar'
import style from '../AboutHeroSection/AboutHeroSection.module.css'
import { NavLink } from 'react-router-dom'
const AboutHeroSection = () => {
    return (
        <>
            <div className={style.heroSection}>
                <NavBar />
                <div className="container ">
                    <div className="row">
                        <div className="col-md-7 hero pt-5 ">

                            <h1 className='hero-section-heading'>  <span >About Us</span></h1>

                            <h5 className='mt-3 fw-bold'>Find the right care right when you need it.</h5>
                            <ul className={style.ul}>
                                <li className='fw-bold'> <i className="fa-solid fa-circle"></i>
                                    Customers can order their medications from the comfrot of their homes, avoiding the need to visit a physical pharmacy.</li>
                                <li className='fw-bold'><i className="fa-solid fa-circle"></i>
                                    Our online plateform is accessible for 24/7. <br />
                                    Allowing users to place orders at their convinence, even during off hours.
                                </li>
                                <li className='fw-bold'><i className="fa-solid fa-circle"></i>
                                    Our online platform maintains our customer confidentiality.
                                    Protecting sencitive medial information.
                                </li>
                            </ul>
                            <div className={`${style.images}d-flex  align-items-center`}>
                                <NavLink>
                                    <img src={require('../../../Assets/images/AppStore.png')} alt="appstore" width='131px' className='me-3' />
                                </NavLink>

                                <NavLink>
                                    <img src={require('../../../Assets/images/GooglePlay.png')} alt="playstore" width='140px' />
                                </NavLink>
                            </div>
                        </div>
                        <div className="col-md-5 text-end ">
                            <img src={require('../../../Assets/images/doctors.png')} alt="animation-img" className='img-fluid ' width='100%' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutHeroSection