import React from 'react'

const Srvc2 = () => {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="d-flex align-items-center my-col justify-content-between">

                        <div className="col-md-6">
                            <div className="priscription-picture d-flex align-items-center w-100 ">
                                <img src={require('../../Assets/images/animation_lk9ib9wf.png')} alt="ad" />
                                <h2>By Medicine Picture</h2>
                            </div>
                            <p>
                              "Here you can upload your medicine images and send them to the sellers, who will then provide a price within 5 minutes. Each seller will send their own offer, and you can select the best offer from the options available."
                            </p>
                        </div>
                        <div className="col-md-5 order">
                            <img src={require('../../Assets/images/asset-1.png')} alt="img" className='img-fluid w-100' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Srvc2