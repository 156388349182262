import React from 'react'

const WhatWeProvide = () => {
    return (
        <>
            <div className="container mb-5 what-we-provide">
                <div className="row">
                    <div className="d-flex relative flex-column align-items-center jusitfy-content-center">
                        <img src={require('../../Assets/images/3d-cartoon-doctor-character-face-mask-removebg-preview.png')} alt="cartoon-img" style={{maxWidth:"350px"}} />
                        <div className='px-5 py-2 absolute' style={{backgroundColor:"#16B5FF", borderRadius:'10px'}}>
                            <h1 className='text-white' style={{fontSize:"39px"}}>What We Provide</h1>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WhatWeProvide