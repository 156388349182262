import React from 'react'

const InputArea = () => {
    return (
        <>
            <div className="input-area my-4">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <input type="text" id="name" placeholder='Name*' className='form-control p-3' />
                        </div>
                        <div className="col-md-6">
                            <input type="email" id="name" placeholder='Email*' className='form-control p-3' />
                        </div>
                    </div>
                    <div className="row  my-4 p-2">
                        <textarea cols="100" rows="20" className='form-control' placeholder='Message'></textarea>
                    </div>
                </div>
                <div className="row ">
                    <div className="d-flex align-items-center text-center p-2 justify-content-center">
                        <p className='px-3'>I have read and agreed the Terms, Conditions and Policy.</p>
                    </div>
                </div>
                <div className="row my-2">
                    <div className="d-flex align-items-center justify-content-center">
                        <button type="button" className="btn btn-lg rounded-1 btn-info" style={{backgroundColor:"#00ADE5" , color:'white'}}>
                            Send Your Message
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InputArea