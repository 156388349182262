import React from 'react'
import NavBar from '../../Components/Navbar/NavBar'
const ServicesHeroSection = () => {
    return (
        <>
            <div className='services-hero-section'>
                <NavBar />

                <div className="container ">
                    <div className="row mt-5">
                        <div className="col-md-7 pt-5 padding hero">

                            <h1 className='hero-section-heading'>  <span >WHAT WE OFFER</span></h1>

                            <div className={`d-flex  align-items-center`}>
                                <p className='mt-4 fw-bold '>At our online pharmacy, we promise to Deliver finest and most exceptional Pharmaceutical experience your can ever imagine. With a commitment to excellence and Customer Satisfaction, we strive to set new standards in the world of online medication services.</p>
                            </div>
                        </div>
                        <div className="col-md-5 text-end ">
                            <img src={require('../../Assets/images/girl.png')} alt="animation-img" className='img-fluid ' width='100%' />
                        </div>
                    </div>
                </div>
            </div></>
    )
}

export default ServicesHeroSection