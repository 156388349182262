    import React from 'react'

    const CustomerSupport = () => {
        return (
            <>
                <div className="container">
                    <div className="row">
                        <div className="d-flex align-items-center my-col justify-content-between">

                            <div className="col-md-5 order">
                                <h1 style={{ color: '#16B5FF' }} >
                                    CUSTOMER <br /> SUPPORT
                                </h1>
                                <div className=" shr">
                                    <div className="row hr">
                                    </div>
                                </div>
                                <p>Relaible customer support is available to address any concerns or issues customers may encounter during the ordering and delivery process.</p>

                            </div>
                            <div className="col-md-5">

                                <img src={require('../../Assets/images/animation_lkjkxyz2.png')} alt="img" className='img-fluid w-100' />
                            </div>
                        </div>
                    </div>
                </div >
            </>
        )
    }

    export default CustomerSupport