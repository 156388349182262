import React from 'react'
import AboutHeroSection from './AboutHeroSection/AboutHeroSection'
import NewTechnologies from '../../Components/NewTechnologies/NewTechnologies'
import Rating from '../../Components/Rating/Rating'
import Footer from '../../Components/Footer/Footer'
import AboutServices from './AboutServices/AboutServices'
import AboutServices2 from './AboutServices/AboutServices2'
import AboutServices3 from './AboutServices/AboutServices3'
import SocialLinks from '../../Components/SocialLinks/SocialLinks'
import HowItWorks from './HowItWorks/HowItWorks'

const About = () => {
    return (
        <>
            <AboutHeroSection />
            <HowItWorks />
            <AboutServices />
            <AboutServices2 />
            <AboutServices3 />
            <NewTechnologies />
            <Rating />
            <Footer />
            <SocialLinks />
        </>
    )
}

export default About