import React from 'react'
import CountUp from 'react-countup';

const Numbers3 = () => {
    return (
        <div className="d-flex align-items-center  justify-content-center flex-column">
            <h2>
                <CountUp start={0} end={80} duration={2.5} enableScrollSpy={true} />+
            </h2>
            <p>Best Pharmacists</p>
        </div>
    )
}

export default Numbers3