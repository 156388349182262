import React from 'react'
import style from './Article.module.css'
const Article = ({ heading, pg, img, color }) => {
    return (
        <>
            <div className={` ${style.article} blog`}>
                <img src={img} alt="img" className='img-fluid' />
                <div className={style.absolute} >
                    <h2 style={{ color: color }}>{heading}</h2>
                    <p style={{ color: color }}>{pg}</p>
                    <button type="button" className="btn btn-info rounded-5 text-white">Read More</button>
                </div>
            </div>
        </>
    )
}

export default Article