import React from 'react'
import ContactHeroSection from './ContactHeroSection'
import HaveAnyQuestion from './HaveAnyQuestion'
import InputArea from './InputArea'
import Footer from '../../Components/Footer/Footer'
import SocialLinks from '../../Components/SocialLinks/SocialLinks'
const ContactUs = () => {
    return (
        <>
           <ContactHeroSection />
           <HaveAnyQuestion />
           <InputArea />
           <Footer />
           <SocialLinks />


        </>
    )
}

export default ContactUs