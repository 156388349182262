import React from 'react'
import { NavLink } from 'react-router-dom'
// import { NavLink } from 'react-router-dom'
const NavBar = () => {
    return (
        <>


            <nav className="navbar navbar-expand-lg">
                <div className="container-fluid " style={{ padding: "0px 70px" }}>
                    <NavLink to='/' className="navbar-brand" href="#">

                        <img src={require('../../Assets/images/logo.png')} alt="logo" width="280px" className='navbar-logo' />


                    </NavLink>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon" > <i className="fa-solid fa-bars"></i> </span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav align-items-center ms-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <NavLink to='/' className="nav-link active" aria-current="page">
                                    Home
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to='/about' className="nav-link " aria-current="page">
                                    About
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to='/my_services' className="nav-link " aria-current="page">
                                    Services
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to='/blogs' className="nav-link active" aria-current="page">
                                    Blog
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to='/contact_us' className="nav-link active" aria-current="page">
                                    Contact
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to='/support'>
                                <button type='button' className='btn rounded-1 btn-success' style={{ backgroundColor: '#7dcd0f' }}>Support</button>
                                </NavLink>
                            </li>
                        </ul>

                    </div>
                </div>
            </nav >














            {/* <div className="navbar" >
                <div className="d-flex align-items-center justify-content-between" style={{ width: '100%' }}>
                    <NavLink to='/'>
                        <div className="logo d-flex align-items-center">
                            <div className="img-box-1">
                                <img src={require('../../Assets/images/istockphoto-1275720974-612x612.png')} alt="logo" />
                            </div>
                            <div className="img-box-2 d-flex flex-column align-items-left">
                                <img src={require('../../Assets/images/Inpharmac.png')} alt="logo2" />
                                <img src={require('../../Assets/images/Your fast tracks for pharmacy deals.png')} alt="logo3" />
                            </div>

                        </div>
                    </NavLink>
                    <ul className='d-flex align-items-center'>
                        <li>
                            <NavLink to='/' className='active'>Home</NavLink>
                        </li>
                        <li>
                            <NavLink to='/'>About</NavLink>
                        </li> 
                        <li>
                            <NavLink to='/'>Services</NavLink>
                        </li>
                        <li>
                            <NavLink to='/'>Blog</NavLink>
                        </li>
                        <li>
                            <NavLink to='/'>Contact</NavLink>
                        </li>
                        <li>
                            <a>
                                
                            </a>
                        </li>
                    </ul>
                </div>
            </div > */}
        </>
    )
}

export default NavBar