import React from 'react'
import Article from './Article'


const Articals = () => {
    return (
        <>
            <div className="container ">
                <div className="row">
                    <div className="col-md-10  mx-auto">
                        <Article heading='The impact of AI on Healthcare.' pg='AI has lead to many people asking how it will impact    healthcare. Here I look at my Personal Experience at thoughts on impact on pharmacy.' img='./images/ezgif-4-560f6c0255.png' />
                    </div>
                    <div className="col-md-10 mt-5 mx-auto">
                        <Article heading='Examining the opportunities and challanges of Pharmacy driven chemicals trial Recruitment' pg='AI has lead to many people asking how it will impact    healthcare.  Here I look at my Personal Experience at thoughts on impact on pharmacy.' img='./images/chemicals.png' color='black' />
                    </div>
                    <div className="col-md-10 mt-5 mx-auto">
                        <Article heading='Having a kid duraing COVID-19 pendemic. My personal expericence.' pg='AI has lead to many people asking how it will impact    healthcare. Here I look at my Personal Experience at thoughts on impact on pharmacy.' img='./images/school.png   ' color='black' />

                    </div>
                    <div className="col-md-10 mt-5 mb-5 mx-auto">

                        <Article heading='Having a kid duraing COVID-19 pendemic. My personal expericence.' pg='AI has lead to many people asking how it will impact    healthcare.  Here I look at my Personal Experience at thoughts on impact on pharmacy.' img='./images/doctor.png   ' color='black' />
                    </div>

                </div>
            </div>
        </>
    )
}

export default Articals