import React from 'react'
import BlogsHeroSection from './BlogsHeroSection'
import RecentArticals from './RecentArticals'
import Articals from './Articals'
import SocialLinks from '../../Components/SocialLinks/SocialLinks'
import Footer from '../../Components/Footer/Footer'
import BlogRating from './BlogRating'
const Blogs = () => {
    return (
        <>
            <BlogsHeroSection />
            <RecentArticals />
            <Articals />
            <BlogRating />
            <Footer />
            <SocialLinks />
        </>
    )
}

export default Blogs