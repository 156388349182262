import React from 'react'

const RecentArticals = () => {
    return (
        <>
            <div className="my-5">

                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-4  width" style={{ padding: '5px 10px', backgroundColor: '#00ADE5', borderRadius: "8px", }}>
                            <h1 className='text-center text-white' >Recent Articals</h1>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RecentArticals